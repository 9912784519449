if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', domLoaded);
} else {
    domLoaded();
}

function domLoaded() {
    initTopNavBarHeight(48, 110, 800, document.querySelector('header.main'));
    initMobileNav();
};

// Resize top nav bar
function initTopNavBarHeight(minHeight, maxHeight, leaveItAloneAtWidth, e) {
    const maxMinHeightDiff = maxHeight - minHeight;

    function setTopNavBarHeight() {
        var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowWidth <= leaveItAloneAtWidth) {
            e.style.height = minHeight + 'px';
            return;
        }
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (maxMinHeightDiff < scrollTop) {
            e.style.height = minHeight + 'px';
        } else {
            e.style.height = maxHeight - scrollTop + 'px';
        }
    }

    window.addEventListener('scroll', setTopNavBarHeight);
    window.addEventListener('resize', setTopNavBarHeight);
    setTopNavBarHeight();
}

// Mobile nav
function initMobileNav() {
    const $body = document.querySelector('body');
    $nav = document.querySelector("header.main .menu-wrapper");
    $hamburger = document.querySelector("header.main .open");
    $topnavCloseButton = document.querySelector("header.main .close");
    $hamburger.addEventListener('click', openMobileNav);
    $topnavCloseButton.addEventListener('click', closeMobileNav);

    function openMobileNav() {
        $nav.classList.add('open')
        $nav.addEventListener('click', closeMobileNavOnClickOutside);
        document.addEventListener('keydown', closeMobileNavOnEsc);
        $body.classList.add('overflow-hidden');
        $hamburger.setAttribute('aria-expanded', 'true');
    }

    function closeMobileNav() {
        $nav.classList.remove('open')
        $nav.removeEventListener('click', closeMobileNavOnClickOutside);
        document.removeEventListener('keydown', closeMobileNavOnEsc);
        $body.classList.remove('overflow-hidden');
        $hamburger.setAttribute('aria-expanded', 'false');
    }

    function closeMobileNavOnClickOutside(event) {
        if (event.target == $nav) closeMobileNav();
    }

    function closeMobileNavOnEsc(event) {
        if (/^Esc(ape)?$/.test(event.key)) closeMobileNav();
    }
}
